// extracted by mini-css-extract-plugin
export var linkstuff = "AlumniCard-module--linkstuff--so2A2";
export var AlumCard = "AlumniCard-module--AlumCard--L0DIG";
export var alumImg = "AlumniCard-module--alumImg--llI8P";
export var alumText = "AlumniCard-module--alumText--8R3bI";
export var alumName = "AlumniCard-module--alumName--oPMhl";
export var alumStage = "AlumniCard-module--alumStage--fjXwZ";
export var tagFlex = "AlumniCard-module--tagFlex--sL7K3";
export var tag = "AlumniCard-module--tag--Qs9e3";
export var mob = "AlumniCard-module--mob--l4F3k";
export var alumDesc = "AlumniCard-module--alumDesc--t70OO";