export const backedByInfo = [
    {
        logo: "https://i.imgur.com/cTzUK56.png",
        link: "https://www.sequoiacap.com/"
    },
    {
        logo: "https://i.imgur.com/S3OIGJH.png",
        link: "https://abc.com/shows/shark-tank"
    },
    {
        logo: "https://i.imgur.com/ksZ11zZ.png",
        link: "https://500.co/"
    },
    {
        logo: "https://i.imgur.com/xmHYsd8.png",
        link: "https://www.titancapital.vc/"
    },
    {
        logo: "https://i.imgur.com/0ITZSXJ.png",
        link: "https://www.better.vc/"
    },
    {
        logo: "https://i.imgur.com/WfpQTYb.png",
        link: "https://blume.vc/"
    },
    {
        logo: "https://i.imgur.com/CSU7GYj.png",
        link: "https://beenext.com/"
    },
    {
        logo: "https://i.imgur.com/u5Jm4JB.png",
        link: "https://www.surgeahead.com/"
    },
    {
        logo: "https://i.imgur.com/bIwP0Xp.png",
        link: "http://www.frzcapital.cn"
    },
    {
        logo: "https://i.imgur.com/oCEXVps.png",
        link: "https://www.coinbase.com/"
    },
]