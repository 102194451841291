import * as React from "react";
import "../fonts/stylesheet.css";

import AlumniCard from "../components/AlumniCard/AlumniCard";
import MentorCard from "../components/MentorCard/MentorCard";
import BackedBy from "../sections/BackedBy/BackedBy";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../sections/Navbar/Navbar";
import NetworkHero from "../sections/NetworkHero/NetworkHero";
import RegBanner from "../sections/RegBanner/RegBanner";

import {
  teamSection,
  teamHeading,
  greyText,
  conqCEOs,
  teamFilter,
  fButtonActive,
  fButtonInactive,
  fButton,
  searchBarContainer,
  searchBar,
  mentorGrid,
  alumniCohort,
} from "../sections/Team/Team.module.css";

const text = {
  fontFamily: "Manrope",
};

const AlumniPage = () => {
  const [alumniState, setAlumniState] = React.useState();
  const [ref, setRef] = React.useState();

  const getRef = () => {
    fetch("https://conquest.prismic.io/api/v2/", {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            getData(res.data.refs[0].ref);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };
  const getData = (key) => {
    fetch(
      "https://conquest.prismic.io/api/v2/documents/search?ref=" +
        key +
        "&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ",
      {
        method: "GET",
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            setAlumniState(res.data.results);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  React.useEffect(() => {
    getRef();
  }, []);

  return (
    <main style={text}>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <NetworkHero
        img1="https://i.imgur.com/WKcmhTt.png"
        img2="https://i.imgur.com/vJNTzNS.png"
        img3="https://i.imgur.com/zgvyniT.png"
        img4="https://i.imgur.com/iXUbHcQ.png"
        img5="https://i.imgur.com/HsdZpsA.png"
        img6="https://i.imgur.com/lbr1LBn.png"
        meet="meet our"
        context="alumni"
        desc="Conquest takes pride in all of our alumni startups who have been associated with us over the years and have had a spectacular success trajectory. Here are some of them."
      />
      <BackedBy />
      {alumniState ? (
        <>
          {alumniState.map((result, id) => (
            <>
              {result.type === "alumni" ? (
                <>
                  <div class={alumniCohort}>2022 COHORT</div>
                  <div class={mentorGrid}>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((alum, id) => (
                          <>
                            {alum.primary.cohort1[0].text === "2022" ? (
                              <AlumniCard
                                name={alum.primary.name[0].text}
                                img={alum.primary.logo.url}
                                stage={alum.primary.stage[0].text}
                                link={alum.primary.link.url}
                                tag1={alum.items[0].tag}
                                tag2={alum.items[1].tag}
                                desc={alum.primary.description[0].text}
                              />
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </>
      ) : null}
      {alumniState ? (
        <>
          {alumniState.map((result, id) => (
            <>
              {result.type === "alumni" ? (
                <>
                  <div class={alumniCohort}>2021 COHORT</div>
                  <div class={mentorGrid}>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((alum, id) => (
                          <>
                            {alum.primary.cohort1[0].text === "2021" ? (
                              <AlumniCard
                                name={alum.primary.name[0].text}
                                img={alum.primary.logo.url}
                                stage={alum.primary.stage[0].text}
                                link={alum.primary.link.url}
                                tag1={alum.items[0].tag}
                                tag2={alum.items[1].tag}
                                desc={alum.primary.description[0].text}
                              />
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </>
      ) : null}
      {alumniState ? (
        <>
          {alumniState.map((result, id) => (
            <>
              {result.type === "alumni" ? (
                <>
                  <div class={alumniCohort}>2020 COHORT</div>
                  <div class={mentorGrid}>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((alum, id) => (
                          <>
                            {alum.primary.cohort1[0].text === "2020" ? (
                              <AlumniCard
                                name={alum.primary.name[0].text}
                                img={alum.primary.logo.url}
                                stage={alum.primary.stage[0].text}
                                link={alum.primary.link.url}
                                tag1={alum.items[0].tag}
                                tag2={alum.items[1].tag}
                                desc={alum.primary.description[0].text}
                              />
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </>
      ) : null}
      {alumniState ? (
        <>
          {alumniState.map((result, id) => (
            <>
              {result.type === "alumni" ? (
                <>
                  <div class={alumniCohort}>2019 COHORT</div>
                  <div class={mentorGrid}>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((alum, id) => (
                          <>
                            {alum.primary.cohort1[0].text === "2019" ? (
                              <AlumniCard
                                name={alum.primary.name[0].text}
                                img={alum.primary.logo.url}
                                stage={alum.primary.stage[0].text}
                                link={alum.primary.link.url}
                                tag1={alum.items[0].tag}
                                tag2={alum.items[1].tag}
                                desc={alum.primary.description[0].text}
                              />
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </>
      ) : null}
      {alumniState ? (
        <>
          {alumniState.map((result, id) => (
            <>
              {result.type === "alumni" ? (
                <>
                  <div class={alumniCohort}>PAST COHORTS</div>
                  <div class={mentorGrid}>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((alum, id) => (
                          <>
                            {alum.primary.cohort1[0].text === "PAST" ? (
                              <AlumniCard
                                name={alum.primary.name[0].text}
                                img={alum.primary.logo.url}
                                stage={alum.primary.stage[0].text}
                                link={alum.primary.link.url}
                                tag1={alum.items[0].tag}
                                tag2={alum.items[1].tag}
                                desc={alum.primary.description[0].text}
                              />
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
        </>
      ) : null}
      <RegBanner />
      <Footer />
    </main>
  );
};

export default AlumniPage;
