import * as React from 'react'

import {
    AlumCard,
    alumImg,
    alumText,
    alumName,
    alumStage,
    tagFlex,
    tag,
    alumDesc,
    mob,
    linkstuff
} from './AlumniCard.module.css'

const AlumniCard = (props) => {
    return (
        <a href={props.link} class={linkstuff} target="_blank" rel="noreferrer">
            <div class={AlumCard}>
                <div class={mob}>
                    <img src={props.img} class={alumImg} />
                    <div class={alumText}>
                        <div class={alumName}>{props.name}</div>
                        <div class={alumStage}>{props.stage}</div>
                        <div class={tagFlex}>
                            <div class={tag}>{props.tag1}</div>
                            <div class={tag}>{props.tag2}</div>
                        </div>
                    </div>
                </div>
                <div class={alumDesc}>{props.desc}</div>
            </div>
        </a>
    )
}

export default AlumniCard;