import * as React from "react";

import {
  invParContainer,
  invParGrid,
  invParLogo,
} from "./BackedBy.module.css";

import { backedByInfo } from "./Backed";

import Heading from "../../components/Heading/Heading";
import Subheading from "../../components/Heading/Subheading";

const BackedBy = () => {
    const [ip, setIP] = React.useState();
    const [ref, setRef] = React.useState();

    const getRef = () => {
        fetch('https://conquest.prismic.io/api/v2/',
            {
                method: 'GET',
            }
        ).then(response => 
            response.json().then(data => ({
                data: data,
                status: response.status
            }) 
        ).then(res => {
            if (res.data) {
                getData(res.data.refs[0].ref)
            } else {
                alert("Authentication failed. Please double check your password.");
            }
        }))
    }
    const getData = (key) => {
        fetch('https://conquest.prismic.io/api/v2/documents/search?ref='+key+'&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ',
            {
                method: 'GET',
            }
        ).then(response => 
            response.json().then(data => ({
                data: data,
                status: response.status
            }) 
        ).then(res => {
            if (res.data) {
                setIP(res.data.results);
            } else {
                alert("Authentication failed. Please double check your password.");
            }
        }))
    }

    React.useEffect(() => {
        getRef();
    }, [])

  return (
    <div className={invParContainer}>
      <Subheading middle="backed" end="by" />
      <div className={invParGrid}>
        {backedByInfo.map((sp, id) => (
            <a href={sp.link} target="_blank">
              <img alt="" className={invParLogo} src={sp.logo} />
            </a>
        ))}
      </div>
    </div>
  );
};

export default BackedBy;
